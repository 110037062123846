const focusInElement = () => {
  const getFocusableNodes = (element: HTMLElement) => {
    const nodes: NodeListOf<HTMLElement> = element.querySelectorAll(
      "a[href], button, details, input:not([type='hidden']), select, [tabindex]:not([tabindex='-1']), textarea"
    );

    return Array(...nodes).filter(
      (element) => !element.hasAttribute("disabled") && !element.getAttribute("aria-hidden")
    );
  };

  const focusElement = (element: HTMLElement | null) => {
    if (!element) {
      return;
    }

    const focusableNodes = getFocusableNodes(element);

    if (focusableNodes.length) {
      focusableNodes[0].focus();
    }
  };

  const retrainFocus = (event: KeyboardEvent, element: HTMLElement | null) => {
    if (!element) {
      return;
    }

    const activeElement = document.activeElement as HTMLElement;
    const focusableNodes = getFocusableNodes(element);
    const focusedItemIndex = focusableNodes.indexOf(activeElement);

    if (event.shiftKey && focusedItemIndex === 0) {
      focusableNodes[focusableNodes.length - 1].focus();
      event.preventDefault();
    }

    if (!event.shiftKey && focusableNodes.length > 0 && focusedItemIndex === focusableNodes.length - 1) {
      focusableNodes[0].focus();
      event.preventDefault();
    }
  };

  return {
    focusElement,
    retrainFocus,
  };
};

export { focusInElement };
